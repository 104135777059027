<template>

  <div>

    <br>

    <div class="top">
      <v-form class="text-center" ref="form" v-model="valid">

        <br>
        <br>

        <b class="obligatorio">SEGURO OBLIGATORIO SOAP 2023</b>

        <br>

        <b class="vigencia">Vigente desde el 1 de abril del 2023</b>

        <br>
        <br>
        <br>

        <v-container style="justify-content: center; display: flex;">

          <v-col style="display:flex; ">

            <div>
              <label :class="vistaTituloPatente">Ingresa tu patente aquí:</label>
              <v-text-field :class="vistaLabelPatente" v-model="patente" :rules="patenteRules" label="Ej: ABDC12"
                required filled solo @change="Mayus()">
              </v-text-field>
            </div>

            <div>
              <label :class="vistaTituloRut">Ingresa tu rut aquí:</label>
              <v-text-field :class="vistaLabelRut" v-model="newRut" label="RUT" :rules="rutRules" required filled solo
                @input="changeRut">
              </v-text-field>
            </div>

          </v-col>

        </v-container>

        <br>
        <br>

        <div
          style="display: flex; flex-direction: row; justify-content: space-between; flex-wrap: wrap; justify-content: center;">

          <vue-recaptcha ref="recaptcha" @verify="verifyMethod" sitekey="6LdZzfwiAAAAANWXDzgv_Qi4HVI3aV7SwHpwd8-u" />

        </div>

        <br>
        <br>

        <v-btn
          style="width: 310px; height: 64px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 0px 10px #2D387C; border: 1px solid #DEDEDE; border-radius: 30px;
          opacity: 1; text-align: center; font: normal normal normal 22px/27px Arial; letter-spacing: 0.66px; color: #D74E80; text-transform: uppercase; opacity: 1;"
          @click="validate()">
          Buscar
        </v-btn>

        <br>
        <br>
        <br>
        <br>

        <div style="max-width: 50%; margin: auto;">
          <template v-if="(patentesLoading == false)">
            <v-data-table :footer-props="{ 'items-per-page-text': '' }" :headers="headers" :items="patentes"
              :items-per-page="5" class="elevation-1">
              <template v-slot:item.actions="{ item }">
                <v-btn dark style="background-color: #138468;" @click="DescargarArchivo(item)">
                  Descargar
                </v-btn>
              </template>
              <template v-slot:item.price="{ item }">
                <label style="margin:auto">{{ formatPrice(item.price) }}</label>
              </template>

            </v-data-table>
          </template>
        </div>

        <br>
        <br>
        <br>
        <br>

      </v-form>
    </div>

  </div>

</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import ShieldService from "../services/shield.service";
import RutUtils from "../utils/rut.utils";
import swal from 'sweetalert';

export default {
  name: "InitForm",
  components: {
    VueRecaptcha,
  },
  data: () => ({
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    newRut: "",
    captcha: null,
    screen: window.screen.width,
    vistaTituloPatente: null,
    vistaLabelPatente: null,
    vistaLabelRut: null,
    vistaTituloRut: null,
    patente: null,
    rut: null,
    patentes: [],
    patentesLoading: true,
    patenteRules: [
      (v) => !!v || "El número de patente es equerido",
    ],
    rutRules: [
      (v) => !!v || "El RUT es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    headers: [
      {
        text: 'Patente',
        sortable: false,
        value: 'patenteNumber',
        align: 'center',
      },
      { text: 'Tipo de Vehículo', value: 'typeVehicle', align: 'center', },
      { text: 'Modelo', value: 'modelVehicle', align: 'center', },
      { text: 'Correo', value: 'personEmail', align: 'center', },
      { text: 'Valor SOAP', value: 'price', align: 'center', },
      { text: 'Descargar', value: 'actions', sortable: false, align: 'center', },
    ],
  }),
  methods: {
    async validate() {
      if (this.$refs.form.validate()) {
        this.patentes = []
        var carritos = await ShieldService.consultaCar(this.patente)
        console.log(carritos)
        if (carritos.status == 'ok') {
          carritos.data.forEach(carrito => {
            var rut = carrito.personRut
            if (rut.toUpperCase() == this.rut.toUpperCase()) {
              this.patentes.push(carrito)
            }
          });
          if (this.patentes.length == 0) {
            swal({
              title: "Rut inválido!",
              text: "Existe un registro correspondiente a la patente, pero el rut ingresado no es el correcto!",
              icon: "info",
              button: "Ok",
            });
            this.patentesLoading = true
          } else {
            this.patentesLoading = false
          }
        } else if (carritos.status == 'failed') {
          swal({
            title: "Lo sentimos!",
            text: "No tenemos registros correspondientes a la patente ingresada.",
            icon: "info",
            button: "Ok",
          });
          this.patentesLoading = true
        } else {
          swal({
            title: "Lo sentimos!",
            text: "No tenemos registros correspondientes a la patente ingresada.",
            icon: "info",
            button: "Ok",
          });
          this.patentesLoading = true
        }
      }
    },
    Mayus() {
      this.patente = this.patente.toUpperCase()
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    DescargarArchivo(item) {
      const url = window.URL.createObjectURL(new Blob([new Uint8Array(item.pdf.data).buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `vehiculo-${item.patenteNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
    },
    changeRut(rut) {
      this.rut = RutUtils.formatRut(rut);
      this.newRut = RutUtils.formatRut(rut);
    },
  },
  async mounted() {
    if (this.screen > 800) {
      this.vistaTituloPatente = 'labelNamePatente'
      this.vistaLabelPatente = 'labelPatente'
      this.vistaTituloRut = 'labelNameRut'
      this.vistaLabelRut = 'labelRut'
    } else if (this.screen <= 800) {
      this.vistaTituloPatente = 'labelNamePatente2'
      this.vistaLabelPatente = 'labelPatente2'
      this.vistaTituloRut = 'labelNameRut2'
      this.vistaLabelRut = 'labelRut2'
    }
  },
};
</script>

<style lang="css" scoped>
.obligatorio {
  text-align: center;
  font: Arial;
  font-size: 30px;
  letter-spacing: 0px;
  color: #573C81;
  opacity: 1;
}

.vigencia {
  width: 244px;
  height: 17px;
  text-align: center;
  font: Arial;
  font-size: 15px;
  letter-spacing: 0px;
  color: #2D387C;
  opacity: 1;
}

.top {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

.titulo {
  text-align: center;
  font: Arial;
  font-size: 15px;
  letter-spacing: 0.23px;
  color: #262626;
  opacity: 1;
}

.subtitulo {
  width: 102px;
  text-align: center;
  font: Arial;
  font-size: 32px;
  letter-spacing: 0.96px;
  color: #FFFFFF;
  opacity: 1;
}

.labelPatente {
  width: 400px;
  height: 58px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: 40%;
  text-transform: uppercase;
  font-weight: bold;
}

.labelNamePatente {
  margin-left: 15%;
  text-align: left;
  font: Arial;
  font-size: 13px;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
}

.labelNamePatente2 {
  text-align: left;
  font: Arial;
  font-size: 13px;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
  margin-left: -10%;
}

.labelPatente2 {
  width: 90%;
  height: 58px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  text-transform: uppercase;
  font-weight: bold;
}

.labelNameRut {
  margin-left: 38%;
  font: Arial;
  font-size: 13px;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
}

.labelRut {
  width: 400px;
  height: 58px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: 54%
}

.labelRut2 {
  width: 90%;
  height: 58px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
}

.labelNameRut2 {
  font: Arial;
  font-size: 13px;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
  margin-left: -25%;
}
</style>