<template>
  <v-container>
    <v-stepper alt-labels v-model="e1" elevation="0" :class="steps">
      <v-stepper-header style="box-shadow: none; margin:auto;">

        <v-stepper-step :complete="e1 > 1" step="1"> Revisa tus datos </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2"> Pagar </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3"> Recibe e imprime tu SOAP </v-stepper-step>

      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <div v-if="loadSoap" class="text-center">
            <br>
            <br>
            <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
            Cargando
          </div>

          <div v-else>

            <CarritoCompra :patentes="patentesCarrito" :e1="e1" @reload="reload(1)"></CarritoCompra>

            <InitForm v-if="loadSoap == false" :carData="carData" :propietarioData="propietarioData"
              @next-step="nextStep(1)" />

          </div>

        </v-stepper-content>

        <v-stepper-content step="2">
          <div v-if="createSoap" class="text-center">
            <br>
            <br>
            <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
            Cargando
          </div>

          <div v-else>

            <SecForm :carData="carData" :propietarioData="propietarioData" :keygen="keygen" :token="token"
              :priceTotal="priceTotal" :patentes="patentes" :plan="plans" @next-step="nextStep" @reload="reload(2)" />

          </div>

        </v-stepper-content>

        <v-stepper-content step="3">
          <div v-if="loading || loadingFinal" class="text-center">
            <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br><br>
            Estamos procesando tu cotización. . .
          </div>

          <ThirdForm v-else-if="!loading && !loadingFinal" @next-step="nextStep(3)" @back="back(3)" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import InitForm from "@/components/InitForm.vue";
import SecForm from "@/components/SecForm.vue";
import ThirdForm from "@/components/ThirdForm.vue";
import ShieldService from "../services/shield.service";
import keygen from 'keygen';
import CarritoCompra from "@/components/Carrito.vue";
import SoapService from "../services/soap.service"
import swal from 'sweetalert';

export default {
  name: "Form",
  components: {
    InitForm,
    SecForm,
    ThirdForm,
    CarritoCompra
  },
  data() {
    return {
      loadSoap: true,
      createSoap: true,
      keygen: null,
      e1: 1,
      steps: null,
      newCar: false,
      screen: window.screen.width,
      loading: false,
      loadingFinal: false,
      token: null,
      patenteData: {},
      patentes: [],
      patenteUtils: null,
      patentesCarrito: [],
      priceTotal: 0,
      typeCliente: null,
      carData: {
        id: null,
        patente: null,
        patenteDv: null,
        year: null,
        type: null,
        marca: null,
        model: null,
        motor: null,
        price: null,
      },
      propietarioData: {
        rut: null,
        nombres: null,
        firtlastname: null,
        secondlastname: null,
        email: null,
        phoneNumber: null,
        propietario: true,
        rutPropietario: null,
        remarque: null,
        campana: null,
      },
      planAuto: null,
      planCamioneta: null,
      planMoto: null,
      planArrastre: null,
      planBus: null,
      plans: {},
      loadPlans: true,
    };
  },
  async mounted() {
    const query = this.$route.query;
    if (query.type) {
      var shieldRes = await ShieldService.getPlans(query.type)
      if (shieldRes.status == 'ok') {
        this.plans = shieldRes.data[0]
        this.loadPlans = false
      }
    }
    this.propietarioData.campana = process.env.VUE_APP_DOMINIO + window.location.pathname
    if (this.screen > 800) {
      this.steps = 'steps'
    } else if (this.screen <= 800) {
      this.steps = 'steps2'
    }
    if (query) {
      if (query.success == 'true') {
        this.e1 = 3
      } else if (query.newCar == 'true') {
        this.newCar = true
        this.keygen = localStorage.getItem('keygen')
        await ShieldService.getCars(this.keygen).then((response) => {
          if (response.status == 'ok') {
            response.data.forEach(car => {
              this.patentesCarrito.push(car)
            });
          }
        })
      }

      if (query.patente && query.email) {

        this.carData.patente = query.patente
        this.propietarioData.email = query.email

        switch (query.type) {
          case 'soap-clientes-scotia':
            this.typeCliente = 'clientes'
            break;
          case 'soap-no-clientes-scotia':
            this.typeCliente = 'no-clientes'
            break;
          case 'soap-colaboradores-scotia':
            this.typeCliente = 'colaboradores'
            break;
          case 'soap-pyme-scotia':
            this.typeCliente = 'pyme'
            break;

          default:
            break;
        }

        var soapRes = await SoapService.consultaSoap(this.carData.patente)

        if (soapRes.data == null) {

          swal({
            title: "Lo sentimos!",
            text: "No existen registros correspondientes a la patente ingresada",
            icon: "info",
            button: "Ok",
          }).then(() => {
            if (query.newCar == 'true') {
              window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '?newCar=true'
            } else {
              window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente
            }
          });
        } else if (soapRes.status == 'ok') {
          this.carData.id = soapRes.data._id
          this.carData.year = soapRes.data.anio_fabricacion
          this.carData.patenteDv = soapRes.data.dv_ppu
          this.carData.type = soapRes.data.tipo_vehiculo
          this.carData.marca = soapRes.data.marca
          this.carData.model = soapRes.data.modelo
          this.carData.motor = soapRes.data.numero_motor

          this.propietarioData.rut = soapRes.data.rut + soapRes.data.dv

          var nombreCompleto = soapRes.data.nombre_razonsocial.split(' ')

          this.propietarioData.nombres = nombreCompleto[3] ? nombreCompleto[2] + ' ' + nombreCompleto[3] : nombreCompleto[2]
          this.propietarioData.firtlastname = nombreCompleto[0]
          this.propietarioData.secondlastname = nombreCompleto[1]
          this.loadSoap = false
        }

        switch (this.carData.type) {
          case 'AUTOMOVIL':
            this.carData.price = this.plans.soap.car.price
            this.propietarioData.remarque = this.plans.soap.car.remarque
            break;
          case 'JEEP':
            this.carData.price = this.plans.soap.car.price
            this.propietarioData.remarque = this.plans.soap.car.remarque
            break;
          case 'STATION WAGON':
            this.carData.price = this.plans.soap.car.price
            this.propietarioData.remarque = this.plans.soap.car.remarque
            break;
          case 'CAMIONETA':
            this.carData.price = this.plans.soap.camioneta.price
            this.propietarioData.remarque = this.plans.soap.camioneta.remarque
            break;
          case 'FURGON':
            this.carData.price = this.plans.soap.camioneta.price
            this.propietarioData.remarque = this.plans.soap.camioneta.remarque
            break;
          case 'MOTO':
            this.carData.price = this.plans.soap.moto.price
            this.propietarioData.remarque = this.plans.soap.moto.remarque
            break;
          case 'CHASIS CABINADO':
            this.carData.price = this.plans.soap.arrastre.price
            this.propietarioData.remarque = this.plans.soap.arrastre.remarque
            break;
          case 'CASA RODANTE MOTORIZADA':
            this.carData.price = this.plans.soap.arrastre.price
            this.propietarioData.remarque = this.plans.soap.arrastre.remarque
            break;
          case 'MINIBUS':
            this.carData.price = this.plans.soap.bus.price
            this.propietarioData.remarque = this.plans.soap.bus.remarque
            break;

          default:
            swal({
              title: "Tipo de vehículo no válido!",
              icon: "info",
              button: "Ok",
            }).then(() => {
              if (query.newCar == 'true') {
                window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/?newCar=true'
              } else {
                window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente
              }
            });
            break;
        }
      }
    }
  },
  methods: {
    async nextStep(step) {
      this.loading = true;
      this.createSoap = true;
      if (step === 1) {
        var CarRes;
        if (this.newCar == false) {
          var key = keygen.url(keygen.medium)
          localStorage.setItem('keygen', key)
          this.keygen = localStorage.getItem('keygen')
          CarRes = await ShieldService.newCar(this.carData.id, this.propietarioData, key, this.plans._id);
          localStorage.setItem('ids', this.ids)
        } else if (this.newCar == true) {
          this.keygen = localStorage.getItem('keygen')
          CarRes = await ShieldService.newCar(this.carData.id, this.propietarioData, this.keygen, this.plans._id);
          this.ids = this.ids + "," + CarRes._id
        }
        this.patentes = []
        this.priceTotal = 0
        this.patenteData = await ShieldService.getCars(this.keygen).then((response) => {
          if (response.status == 'ok') {
            response.data.forEach(car => {
              this.patentes.push(car)
              this.priceTotal = this.priceTotal + car.price
            });
          }
        })
        this.e1 = 2;
      }
      this.loading = false;
      this.createSoap = false;
    },
    back(step) {
      if (step === 2) {
        this.e1 = 2;
      }
    },
    async reload(step) {
      this.patentes = []
      this.priceTotal = 0
      this.patenteData = await ShieldService.getCars(this.keygen).then((response) => {
        if (response.status == 'ok') {
          response.data.forEach(car => {
            this.patentes.push(car)
            this.priceTotal = this.priceTotal + car.price
          });
        } else if (response.status == 'failed' && step == 2) {
          swal({
            title: "No posee SOAP's en su carrito!",
            text: "Será redirigido al inicio de la plataforma",
            icon: "info",
            button: "Ok",
          }).then(() => {
            window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente
          });
        }
      })
    },
  },
};
</script>

<style lang="scss">
.theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
  background: rgba(255, 0, 0, 0.38);
  border-radius: 50%;
  border: solid 2px white;
  overflow: hidden;
  /* Evitar que el contenido se visualice fuera del borde*/
}

.theme--light.v-stepper .v-stepper__label {
  text-align: center;
  font: Arial;
  font-size: 15px;
  letter-spacing: 0.23px;
  color: #262626;
  opacity: 1;
  font-size: 15px;
  width: 208px;
}

.v-input .v-label {
  text-align: left;
  font-size: 12px;
  font: Arial;
  letter-spacing: 0.18px;
  color: #2D387C;
  opacity: 1;
}

span.v-stepper__step__step.primary {
  width: 24px;
  height: 24px;
  border: 1px solid rgba(112, 112, 112, 1);
  opacity: 1;
  background: transparent linear-gradient(140deg, rgba(215, 78, 128, 1) 0%, rgba(87, 60, 129, 1) 100%) 0% 0% no-repeat padding-box !important;
}

.steps {
  min-width: 80%;
  margin-top: 5%;
}

.steps2 {
  min-width: 100%;
}
</style>